import React from "react";
import { useTranslation } from 'react-i18next';
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Navegation from "./Navegation";

const Header = (props) => {
  const { t } = useTranslation();

  const scroll=()=>{
    if (window.location.pathname === "/") {
      const anchor = document.querySelector("#home");

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-between">
        <div className="container">
          <Link className="navbar-brand nav-link" onClick={scroll} to="/">
            <img
              id="logo-header"
              src="img/logo-header.png"
              alt="Logo Tempora Wines"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li
                className="nav-item active"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
              >
                <Link className="nav-link" onClick={scroll}  to="/">
                {t('header.nosotros')} 
                {/* <span className="sr-only">(current)</span> */}
                </Link>
              </li>
              <li
                id="nav-item-vino"
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
              >
                <Link className="nav-link" to="vinos">
                {t('header.vinos')}
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
              >
                <Link className="nav-link" to="wine-friends">
                {t('header.winefriends')}
                </Link>
              </li>
              <li
                className="nav-item"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
              >
                <Link className="nav-link" to="contacto">
                {t('header.contacto')}
                </Link>
              </li>
              <Navegation size={true}/>
            </ul>
          
          </div>
        </div>
      </nav>
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default Header;
