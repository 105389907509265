import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Formulario = () => {
  const { t } = useTranslation();
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [consulta, setConsulta] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("nombre", nombre);
    data.append("email", email);
    data.append("telefono", telefono);
    data.append("direccion", direccion);
    data.append("consulta", consulta);
    let url = process.env.PUBLIC_URL + "/mail/mailer_tempora.php";
    // let url = "https://argency.com.ar/mail/mailer_tempora.php";
    await fetch(url, {
      method: "POST",
      body: data,
    })
      .then(async (resp) => {
        // console.log(resp);
        let convert = await resp.json();
        return convert;
        //  const res= await resp.json();
      })
      .then((res) => {
        if (res.ok === "200") {
          setNombre("");
          setEmail("");
          setTelefono("");
          setDireccion("")
          setConsulta("");
          setError("200");
        } else {
          setError("500");
        }
      })
      .catch(function (err) {
        setError("500");
      });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-6" id="wrapper-form">
        <form
          // name="sentMessage"
          // id="contactForm"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <input
              type="text"
              required
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              placeholder={`${t("c-formulario.nombre")}`}
              // data-validation-required-message="Por favor ingresá tu nombre."
              className="form-control input_contacto"
              name="name"
              // id="name"
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              required
              // pattern=".+@globex.com"
              size="30"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={`${t("c-formulario.email")}`}
              // data-validation-required-message="Por favor ingresá tu dirección de e-mail."
              className="form-control input_contacto"
              name="email"
              // id="mail"
            />
          </div>
          <div className="form-group">
            <input
              type="number"
              required
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              placeholder={`${t("c-formulario.telefono")}`}
              // data-validation-required-message="Por favor ingresá tu teléfono."
              className="form-control input_contacto"
              name="phone"
              // id="telefono"
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              required
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
              placeholder={`${t("c-formulario.direccion")}`}
              // data-validation-required-message="Por favor ingresá tu dirección."
              className="form-control input_contacto"
              // name="direccion"
              // id="direccion"
            />
          </div>
          <textarea
            // name="mensaje"
            // id="mensaje"
            value={consulta}
            onChange={(e) => setConsulta(e.target.value)}
            placeholder={`${t("c-formulario.consulta")}`}
            className="form-control"
            required
            // data-validation-required-message="Por favor ingresá tu mensaje."
            // aria-invalid="false"
          ></textarea>
          <div style={{ textAlign: "center", paddingTop: 20 }}>
            <button
              type="submit"
              className="btn btn-xl btn_enviar"
              // id="btn_enviar"
              // onClick={handleSubmit}
            >
              {t("c-formulario.enviar")}
            </button>
          </div>
          <br />
          <div className="form-group">
            <div id="success">
              {error === null ? null : error === "200" ? (
                <div class="alert alert-success">
                  <button
                    type="button"
                    class="close"
                    onClick={() => setError(null)}
                  >
                    &times;
                  </button>
                  <strong>Tu mensaje ha sido enviado. </strong>
                </div>
              ) : (
                <div class="alert alert-danger">
                  <button
                    type="button"
                    class="close"
                    onClick={() => setError(null)}
                  >
                    &times;
                  </button>
                  <strong>
                    Lo sentimos en este momento el servidor no está funcioando.
                    Intentalo de nuevo en unos minutos
                  </strong>
                </div>
              )}
            </div>
          </div>
        </form>
        <Link to="">
          <div className="btn-para-unirse">
            <p className="txt-para-unirse">{t("c-formulario.separte")}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};
