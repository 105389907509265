import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Formulario } from "./Formulario";

const Contactos = () => {
  const { t } = useTranslation();
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
  return (
    <React.Fragment>
      <section id="home">
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="video/fondo-wine-friends.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div
                className="wrapper-title-home"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <div className="wrapper-titles-home">
                  <h2 
                   className={
                    status.isMobile.Android() === true
                      ? "title-home-wine-friends didot"
                      : "title-home-wine-friends"
                  }
                  // className="title-home-wine-friends"
                  >
                    {t("c-contactos.textimg-1")}
                    <span className="title-home-wine-friends-dos">
                      {" "}
                      {t("c-contactos.textimg-2")}
                    </span>
                  </h2>
                </div>
                <div className="wrapper-titles-home">
                  <h2 
                   className={
                    status.isMobile.Android() === true
                      ? "title-home-wine-friends didot"
                      : "title-home-wine-friends"
                  }
                  // className="title-home-wine-friends"
                  >
                    {" "}
                    {t("c-contactos.textimg-3")}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="formulario">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-9 ">
              <h2 
                className={
                  status.isMobile.Android() === true
                    ? "title-form didot"
                    : "title-form"
                }
              // className="title-form"
              > {t("c-contactos.titulo")}</h2>
              <p className="txt-form only-desktop">
                {t("c-contactos.m-parrafo-1")} <br />
                {t("c-contactos.m-parrafo-2")}
              </p>
              <p 
               className={
                status.isMobile.Android() === true
                  ? "txt-form only-mobile didot"
                  : "txt-form only-mobile"
              }
              // className="txt-form only-mobile"
              >{t("c-contactos.parrafo")}</p>
              <div className="container-cajas-tempora">
                <img
                  className="cajas-tempora"
                  src="img/cajas-tempora-wines-ok.jpg"
                  alt="cajas Tempora Wines"
                />
              </div>
            </div>
          </div>
          <Formulario />
        </div>
      </section>
    </React.Fragment>
  );
};

export default Contactos;
