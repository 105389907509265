import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./i18n";
import Routes from "./routes";
import "./index.css";
import { CookiesProvider } from "react-cookie";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Suspense
    fallback={
      <div id="preloader">
        <div>
          <img id="loader" src="img/loading.gif" alt="loader Tempora Wines" />
        </div>
      </div>
    }
  >
    <CookiesProvider>
      <Routes />
    </CookiesProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
