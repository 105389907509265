import React, { useEffect } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";

const Legales = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  return (
    <section id="legales-section">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div
            className="col-12"
            style={{ maxWidth: 960 }}
            id="wrapper-info-legales"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <img
              id="img-legales"
              className="img-fluid rounded mx-auto d-block"
              src="img/logo-legales.png"
              alt="Logo Tempora Wines"
            />
            <img
              id="img-txt-legales"
              className="img-fluid rounded mx-auto d-block"
              src="img/texto-legales.png"
              alt="Tempora Wines"
            />

            <h3
              className=" title-legales"
              style={{ color: "#7c7c7c", fontFamily: "gotham-book" }}
            >
              Al ingresar a nuestra página, nos confirmas que tienes la edad
              suficiente para beber alcohol en tu país.
            </h3>

            <h3
              className=" title-legales"
              style={{ color: "#7c7c7c", fontFamily: "gotham-book" }}
            >
              By entering this site, you confirm you are of legal drinking age
              in your country.
            </h3>

            <h3
              className=" title-legales"
              style={{ color: "#7c7c7c", fontFamily: "gotham-book" }}
            >
              Ao ingressar na nossa página você confirma que é maior de idade
              para beber álcool no seu país.
            </h3>
            {/* <ul id="list-legales">
              <li id="li-barrita"> */}
            <div className={"container-boton-legal"}>
              <Link to="/">
                <div
                  // style={style.boton}
                  className="boton-idioma"
                  onClick={() => handleClick("es")}
                >
                  ESPAÑOL
                </div>
              </Link>
              {/* </li>
              <li> */}
              <Link to="/">
                <div
                  // style={style.boton}
                  className="boton-idioma"
                  onClick={() => handleClick("en")}
                >
                  ENGLISH
                </div>
              </Link>
              <Link to="/">
                <div
                  // style={style.boton}
                  className="boton-idioma"
                  onClick={() => handleClick("port")}
                >
                  PORTUGUÊS
                </div>
              </Link>
            </div>
            {/* </li>
            </ul> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Legales;
