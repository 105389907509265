import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextZoomIn } from "../components/TextZoomIn";
import { Link } from "react-router-dom";

export const Vinos = () => {
  const { t } = useTranslation();
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <section id="home-replica">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div
                className="wrapper-title-home"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <TextZoomIn />
                <img
                  className="img-fluid only-mobile"
                  src="img/fondo-vino-malbec.jpeg"
                  alt="Malbec"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECCIÓN VINOS HOME--> */}
      <section id="vinos-home-replica">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-sm-5">
              <img
                className="img-fluid img-botellas-replica"
                src="img/botella-malbec.png"
                alt="Tempora Malbec"
              />
              <h3 className="title-vinos-home-dorado-replica">
                {t("v-vinos.carisma-banner-1")}
              </h3>
              <h3 className="title-vinos-home-replica">
                {t("v-vinos.carisma-banner-2")}
              </h3>
              <a
                className="only-mobile"
                href={`${t("v-vinos.pdf-1")}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="btn-ficha-tecnica">
                  <p className="txt-ficha-tecnica">{t("v-vinos.ficha")}</p>
                </div>
              </a>
              <Link className="only-mobile" to="/wine-friends">
                <div className="btn-para-comprar">
                  <p className="txt-para-comprar">{t("v-vinos.comprar")}</p>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-4">
              <div className="wrapper-ficha-tenica">
                <p className="txt-ficha-inicial">
                  <span 
                  // className="title-ficha"
                  className={
                    status.isMobile.Android() === true
                      ? "title-ficha didot"
                      : "title-ficha"
                  }
                  >
                    {t("v-vinos.carisma-titulo-1")}
                  </span>
                  {t("v-vinos.carisma-titulo-2")}
                {/* </p>
                <p className="txt-ficha"> */}
                  {t("v-vinos.carisma-texto-1")} <br />
                  <br />
                </p>
                <p className="txt-ficha">{t("v-vinos.carisma-texto-2")}</p>
                <img
                  className="img-fichas"
                  src="img/malbec-ficha.png"
                  alt="Tempora Malbec"
                />
                <a
                  className="only-desktop"
                  href={`${t("v-vinos.pdf-1")}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="btn-ficha-tecnica">
                    <p className="txt-ficha-tecnica">{t("v-vinos.ficha")}</p>
                  </div>
                </a>
                <Link className="only-desktop" to="/wine-friends">
                  <div className="btn-para-comprar">
                    <p className="txt-para-comprar">{t("v-vinos.comprar")}</p>
                  </div>
                </Link>
              </div>
            </div>
            <img
              id="img-mapa-mendoza"
              className="img-fluid img-botellas-replica only-desktop"
              src="img/mapa-mendoza.png"
              alt="Tempora Wines"
              data-aos="fade-left"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </section>

      <section className="only-desktop" id="home-2-replica">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div
                className="wrapper-title-home-replica"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <TextZoomIn />
                <img
                  className="img-fluid only-mobile"
                  src="img/fondo-vino-malbec.jpeg"
                  alt="Malbec"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECCIÓN VINOS HOME 2--> */}
      <section id="vinos-home-2-replica">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-sm-5">
              <img
                className="img-fluid only-mobile"
                src="img/fondo-vino-carmenere.jpeg"
                alt="Tempora Carmenere"
              />
              <img
                className="img-fluid img-botellas-replica"
                src="img/botella-carmenere.png"
                alt="Tempora Carmenere"
              />
              <h3 className="title-vinos-home-dorado-replica">
                {t("v-vinos.refinado-banner-1")}
              </h3>
              <h3 className="title-vinos-home-replica">
                {t("v-vinos.refinado-banner-2")}
              </h3>
              <a
              rel="noopener noreferrer"
                className="only-mobile"
                href={`${t("v-vinos.pdf-2")}`}
                target="_blank"
              >
                <div className="btn-ficha-tecnica">
                  <p className="txt-ficha-tecnica">{t("v-vinos.ficha")}</p>
                </div>
              </a>
              <Link className="only-mobile" to="/wine-friends">
                <div className="btn-para-comprar">
                  <p className="txt-para-comprar">{t("v-vinos.comprar")}</p>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-4">
              <div className="wrapper-ficha-tenica">
                <p className="txt-ficha-inicial">
                  <span 
                    className={
                      status.isMobile.Android() === true
                        ? "title-ficha didot"
                        : "title-ficha"
                    }
                  // className="title-ficha"
                  >
                    {t("v-vinos.refinado-titulo-1")}
                  </span>
                  {t("v-vinos.refinado-titulo-2")}
                {/* </p>
                <p className="txt-ficha"> */}
                  {t("v-vinos.refinado-texto-1")} <br />
                  <br /> {t("v-vinos.refinado-texto-2")} <br />{" "}
                  {t("v-vinos.refinado-texto-3")}
                </p>
                <img
                  className="img-fichas"
                  src="img/carmenere-ficha.png"
                  alt="Tempora Carmenere"
                />

                <a
                  className="only-desktop"
                  rel="noopener noreferrer"
                  href={`${t("v-vinos.pdf-2")}`}
                  target="_blank"
                >
                  <div className="btn-ficha-tecnica">
                    <p className="txt-ficha-tecnica">{t("v-vinos.ficha")}</p>
                  </div>
                </a>
                <Link className="only-desktop" to="/wine-friends">
                  <div className="btn-para-comprar">
                    <p className="txt-para-comprar">{t("v-vinos.comprar")}</p>
                  </div>
                </Link>
              </div>
            </div>
            <img
              id="img-mapa-mendoza"
              className="img-fluid img-botellas-replica only-desktop"
              src="img/mapa-chile.jpeg"
              alt="Tempora Wines"
              data-aos="fade-left"
              data-aos-duration="3000"
            />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
