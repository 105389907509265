import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Contactos from "../components/Contactos";
import { Vinos } from "./Vinos";
import { Vinos as Cvinos } from "../components/Vinos";
const Home = () => {
  const { t } = useTranslation();
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div>
          <img id="loader" src="img/loading.gif" alt="loader Tempora Wines" />
        </div>
      </div> */}

      {/* <!-- SECCIÓN HOME--> */}
      <section id="home">
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="video/fondo-home.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-sm-6"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="wrapper-title-home"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <div className="wrapper-titles-home only-desktop">
                  <h2 className="title-home">{t("home.banner-1-la")}</h2>
                  <h2 className={"title-home-dos"}>
                    {t("home.banner-1-busqueda")}
                  </h2>
                </div>
                <div className="wrapper-titles-home only-desktop">
                  <h2 className="title-home">{t("home.banner-1-la")}</h2>
                  <h2 className="title-home-dos">
                    {t("home.banner-1-espera")}
                  </h2>
                </div>
                <div className="wrapper-titles-home only-desktop">
                  <h2 className="title-home">
                    {t("home.banner-1-finalmente")}
                  </h2>
                </div>
                <div className="wrapper-titles-home only-desktop">
                  <h2 className="title-home">{t("home.banner-1-la")}</h2>
                  <h2 className="title-home-dos">{t("home.banner-1-dicha")}</h2>
                </div>
                <div className="wrapper-titles-home only-mobile">
                  <h2 className="title-home">
                    {t("home.banner-1-la")}
                    <span
                      className={
                        status.isMobile.Android() === true
                          ? "title-home-dos didot"
                          : "title-home-dos"
                      }
                    >
                      {t("home.banner-1-busqueda")}
                    </span>
                    {t("home.banner-1-la")}
                    <span
                      className={
                        status.isMobile.Android() === true
                          ? "title-home-dos didot"
                          : "title-home-dos"
                      }
                    >
                      {t("home.banner-1-espera")}
                    </span>
                  </h2>

                  {/* <!-- <h2 className="title-home">
                  LA
                  <span className="title-home-dos">
                    Espera
                  </span>
                </h2> --> */}
                </div>
                <div className="wrapper-titles-home only-mobile">
                  <h2 className="title-home">
                    {t("home.banner-1-finalmente")} {t("home.banner-1-la")}
                    <span 
                     className={
                      status.isMobile.Android() === true
                        ? "title-home-dos didot"
                        : "title-home-dos"
                    }
                    // className="title-home-dos"
                    >
                      {t("home.banner-1-dicha")}
                    </span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECCION NOSOTROS --> */}
      <section id="nosotros">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-4 col-lg-3" id="col-title-nosotros">
              <h2 className="title-nosotros">
                {t("home.efimera-titulo-1")}
                <br />
                {t("home.efimera-titulo-2")}
              </h2>
              <div className="border-negro only-desktop"></div>
              <div className="border-negro-mobile only-mobile"></div>
            </div>
            <div className="col-12 col-sm-8 col-lg-9">
              <div
                className="wrappera-nosotros-txt"
                data-aos="fade-down"
                data-aos-duration="2500"
              >
                <p className="txt-nosotros only-desktop">
                  {t("home.efimera-parrafo-1")} <br />
                  {t("home.efimera-parrafo-2")} <br />
                  {t("home.efimera-parrafo-3")}
                </p>
                <p
                  className={
                    status.isMobile.Android() === true
                      ? "txt-nosotros didot only-mobile"
                      : "txt-nosotros only-mobile"
                  }
                >
                  {t("home.efimera-parrafo")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid" id="wrapper-nosotros-segundo">
          <div className="container container-edit">
            <div className="row">
              <div className="col-12 col-sm-4">
                <h2 className="title-nosotros-wrapper">
                  {t("home.historia-titulo-1")}
                  <br />
                  {t("home.historia-titulo-2")}
                </h2>
                <div className="border-dorado only-desktop"></div>
                <div className="border-dorado-mobile only-mobile"></div>
              </div>
              <div className="col-12 col-sm-5">
                <div className="wrappera-nosotros-txt">
                  <p
                   className={
                    status.isMobile.Android() === true
                      ? "txt-nosotros-wrapper didot"
                      : "txt-nosotros-wrapper"
                  }
                    // className="txt-nosotros-wrapper"
                    data-aos="fade-down"
                    data-aos-duration="2500"
                  >
                    {t("home.historia-parrafo")}
                  </p>
                  <div className="col-6">
                    <img
                      className="img-fluid"
                      src="img/firma-rodrigo.png"
                      alt="Rodrigo Fontecilla"
                    />
                    <p className="txt-firma">RODRIGO FONTECILLA LIRA</p>
                    <p className="txt-firma-bajada"> {t("home.firma")} </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4"></div>
              <img
                className="img-fluid only-desktop"
                id="img-rodrigo"
                src="img/rodrigo.png"
                alt="rodrigo"
                data-aos="fade-left"
                data-aos-duration="3000"
                data-aos-anchor="#wrapper-nosotros-segundo"
                data-aos-anchor-placement="top-center"
              />
              <img
                className="img-fluid only-mobile"
                id="img-rodrigo"
                alt="rodrigo"
                src="img/rodrigo.png"
                data-aos="fade-up"
                data-aos-duration="2000"
              />
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECCIÓN VIAJE SIN FIN--> */}
      <section id="viaje-sin-fin" data-aos="fade-up" data-aos-duration="3000">
        <div className="container container-edit">
          <div className="row">
            <div className="col-12 col-sm-4 col-md-3">
              <h2 className="title-viaje-sin-fin">
                {t("home.viaje-titulo-1")}
                <br />
                {t("home.viaje-titulo-2")}
              </h2>
              <div className="border-dorado-sin-fin only-desktop"></div>
              <div className="border-dorado-mobile only-mobile"></div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="wrappera-nosotros-txt">
                <p 
                 className={
                  status.isMobile.Android() === true
                    ? "txt-viaje-sin-fin didot"
                    : "txt-viaje-sin-fin"
                }
                // className="txt-viaje-sin-fin"
                >{t("home.viaje-parrafo")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECCIÓN FOTO HOME--> */}
      <section id="foto-home">
        <div className="container"></div>
      </section>

      {/* <!-- SECCIÓN VINOS HOME--> */}
      <section id="vinos-home">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6">
              <h2 className="title-principal-vinos-home">
                {t("home.simbolo-titulo-1")} <br />
                {t("home.simbolo-titulo-2")} <br />
                {t("home.simbolo-titulo-3")} <br />
                {t("home.simbolo-titulo-4")}
              </h2>
              <div className="border-dorado-sin-fin only-desktop"></div>
              <div className="border-dorado-mobile only-mobile"></div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="wrappera-nosotros-txt">
                <p 
                  className={
                    status.isMobile.Android() === true
                      ? "txt-principal-vinos-home didot"
                      : "txt-principal-vinos-home"
                  }
                // className="txt-principal-vinos-home"
                >
                  {t("home.simbolo-parrafo")}
                </p>
              </div>
            </div>
            <Cvinos />
          </div>
        </div>
      </section>

      <Vinos />

      <Contactos style={{ marginTop: 0 }} />
    </React.Fragment>
  );
};

export default Home;
