import React, { useEffect } from "react";
import Contactos from "../components/Contactos";
import { Vinos } from "../components/Vinos";

export const WineFriends = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Contactos />
      <section style={{ padding: "0 0 50px 0", overflow: "hidden" }}>
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <Vinos />
        </div>
      </section>
    </React.Fragment>
  );
};
