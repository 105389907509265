import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Vinos = () => {
  const { t } = useTranslation();

  const handleClickVinos = async (vino) => {

      setTimeout(() => {
        const anchor = document.querySelector(`${vino}`);

        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 500);
  };
  return (
    <React.Fragment>
      <div className="col-12 col-sm-4">
      <Link to={"/vinos"}>
          <img
          onClick={()=>handleClickVinos("#vinos-home-replica")}
            className="img-fluid img-botellas"
            src="img/botella-malbec.png"
            alt="Tempora Malbec"
          />
        </Link>
        <h3 className="title-vinos-home-dorado">{t("c-vinos.carisma-1")}</h3>
        <h3 className="title-vinos-home">{t("c-vinos.carisma-2")}</h3>
        <div className="btn-vinos-home">
          <Link to={"/vinos"}>
            <p onClick={()=>handleClickVinos("#vinos-home-replica")} className="txt-vinos-home">{t("c-vinos.ver")}</p>
          </Link>
        </div>
      </div>
      <div className="col-12 col-sm-4">
      <Link to={"/vinos"}>
          <img
          onClick={()=>handleClickVinos("#vinos-home-2-replica")}
            className="img-fluid img-botellas"
            src="img/botella-carmenere.png"
            alt="Tempora Malbec"
          />
        </Link>
        <h3 className="title-vinos-home-dorado">{t("c-vinos.refinado-1")}</h3>
        <h3 className="title-vinos-home">{t("c-vinos.refinado-2")}</h3>
        <div className="btn-vinos-home">
        <Link to={"/vinos"}>
            <p onClick={()=>handleClickVinos("#vinos-home-2-replica")} className="txt-vinos-home">{t("c-vinos.ver")}</p>
            </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
