import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

const Footer = () => {
   const { t } = useTranslation();
   const scroll=()=>{
    if (window.location.pathname === "/") {
      const anchor = document.querySelector("#home");

      if (anchor) {
        anchor.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }
    return (
        <section id="contacto">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <img
                id="logo-footer"
                src="img/logo-footer.png"
                alt="Logo Tempora Wines"
              />
              <ul id="list-menu-footer">
                  <Link to="/" className="nav-link" onClick={scroll} id="nav-link-footer">
                  <li>{t('footer.nosotros')}</li>
                  </Link>
               
                <Link to="/vinos">
                  <li>{t('footer.vinos')}</li>
                </Link>
                <Link to="/wine-friends">
                  <li>{t('footer.winefriends')}</li>
                </Link>
                <Link to="/contacto">
                  <li>{t('footer.contacto')}</li>
                </Link>
              </ul>
            </div>
            <div className="col-12 col-sm-6">
              <ul id="list-redes-footer">
                <a 
                rel="noopener noreferrer"
                href="https://www.instagram.com/temporawines/" 
                target="_blank"
                >
                  <img
                    className="ico-footer"
                    src="img/logo-insta-footer.png"
                    alt="Tempora Wines"
                  />
                </a>
                <a 
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.vivino.com/tempora-decano-di-vino-malbec-red-wine-v-aajt9/w/4876443?year=U.V.">
                  <img
                    className="ico-footer"
                    src="img/logo-vivino-footer.png"
                    alt="Tempora Wines"
                  />
                </a>
              </ul>
            </div>
            <div className="col-12" id="footer-final">
              <p className="txt-footer-final">{t('footer.terminos')}</p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default Footer
