import React from "react";
import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom";

import { useCookies } from "react-cookie"
import Legales from "./views/Legales";
import Home from "./views/Home"
import RouteWithHeader from "./components/RouteWithHeader";
import { Contacto } from "./views/Contacto";
import { Vinos } from "./views/Vinos";
import { WineFriends } from "./views/WineFriends";
// import RouteWithHeader from "./components/RouteWithHeader";



const Routes = () => {
  const [cookies, setCookie] = useCookies(["legacy"]);

  const showLegacy = () => {
    let legacy = cookies.legacy;
    if (Boolean(legacy==="true")) {
      return;
    } else {
      // setCookie('legacy', "true",{maxAge:30*60});
      setCookie('legacy', "true",{maxAge:30});
      return (<Redirect to="/legal" />);
    }
  };
  return (
    <BrowserRouter>
      {/* <Redirect to="/legacy" /> */}
      {showLegacy()}
      <Switch>
    
        {/* <RouteWithHeader exact path="/" component={Home} /> */}
        <Route exact path="/legal" component={Legales} />
        <RouteWithHeader exact path="/" component={Home} />
        <RouteWithHeader exact path="/vinos" component={Vinos} />
        <RouteWithHeader exact path="/wine-friends" component={WineFriends} />
        <RouteWithHeader exact path="/contacto" component={Contacto} />
        {/* <Route path="*" component={PageError} /> */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

