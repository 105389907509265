import React from "react";
import i18next from "i18next";

const Navegation = (props) => {
  function handleClick(lang) {
    i18next.changeLanguage(lang);
  }
  return (
    <div
      className={props.size?"container-idiomas":"container-idiomas-l"}
    >
      <ul style={{ padding: 0 }}>
        <li
          onClick={() => handleClick("es")}
          style={{
            borderRight: "2px solid #7c7c7c",
            float: "left",
            listStyle: "none",
            // textAlign: size?"initial":"center",
            width: props.size ? 50 : 70,
          }}
        >
          {props.size ? (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px", fontSize: 11,color:"#7c7c7c" }}
            >
              ESP
            </p>
          ) : (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px" }}
            >
              ESP
            </p>
          )}
        </li>

        <li
          onClick={() => handleClick("en")}
          style={{
            float: "left",
            listStyle: "none",
            borderRight: "2px solid #7c7c7c",
            // textAlign: size?"initial":"center",
            width: props.size ? 50 : 70,
          }}
        >
          {props.size ? (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px", fontSize: 11,color:"#7c7c7c" }}
            >
              ENG
            </p>
          ) : (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px" }}
            >
              ENG
            </p>
          )}
        </li>
        <li
          onClick={() => handleClick("port")}
          style={{
            float: "left",
            listStyle: "none",
            // textAlign: size?"initial":"center",
            width: props.size ? 50 : 70,
          }}
        >
          {props.size ? (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px", fontSize: 11,color:"#7c7c7c" }}
            >
              PORT
            </p>
          ) : (
            <p
              className="title-legales"
              style={{ cursor: "pointer", margin: "0 10px" }}
            >
              PORT
            </p>
          )}
        </li>
    
      </ul>
    </div>
  );
};

export default Navegation;
