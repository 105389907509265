import React,{useState} from "react";
import { useTranslation } from 'react-i18next';

export const TextZoomIn = () => {
    const { t } = useTranslation();
    const [status] = useState({
      isMobile: {
        Windows: function () {
          return /IEMobile/i.test(navigator.userAgent);
        },
        Android: function () {
          return /Android/i.test(navigator.userAgent);
        },
        BlackBerry: function () {
          return /BlackBerry/i.test(navigator.userAgent);
        },
        iOS: function () {
          return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        },
      },
    });
  return (
    <React.Fragment>
      <div className="wrapper-titles-home-replica only-desktop">
        <h2 className="title-home-replica">{t('text-zoom.text-1')}</h2>
      </div>
      <div className="wrapper-titles-home-replica only-desktop">
        <h2 className="title-home-replica">{t('text-zoom.text-2')}</h2>
        <h2 className="title-home-dos-replica ">{t('text-zoom.text-3')}</h2>
      </div>
      <div className="wrapper-titles-home-replica only-desktop">
        <h2 className="title-home-replica">{t('text-zoom.text-4')}</h2>
      </div>
      <div className="wrapper-titles-home-replica only-desktop">
        <h2 className="title-home-replica">{t('text-zoom.text-5')}</h2>
      </div>
      <div className="wrapper-titles-home-replica only-desktop">
        <h2 className="title-home-dos-replica ">{t('text-zoom.text-6')}</h2>
      </div>
      <div className="wrapper-titles-home-replica only-mobile">
        <h2 className="title-home-replica">{t('text-zoom.text-7')}</h2>
        <h2 
         className={
          status.isMobile.Android() === true
            ? "title-home-dos-replica didot"
            : "title-home-dos-replica"
        }
        // className="title-home-dos-replica "
        >{t('text-zoom.text-3')}</h2>
        <h2 className="title-home-replica">{t('text-zoom.text-4')}</h2>
        <h2 className="title-home-replica">{t('text-zoom.text-5')}</h2>
        <h2 
         className={
          status.isMobile.Android() === true
            ? "title-home-dos-replica didot"
            : "title-home-dos-replica"
        }
        // className="title-home-dos-replica "
        >{t('text-zoom.text-6')}</h2>
      </div>
    </React.Fragment>
  );
};
