import React,{useEffect,useState} from "react";
import { useTranslation } from 'react-i18next';
import { Formulario } from "../components/Formulario";

export const Contacto = () => {
  const { t } = useTranslation();
  const [status] = useState({
    isMobile: {
      Windows: function () {
        return /IEMobile/i.test(navigator.userAgent);
      },
      Android: function () {
        return /Android/i.test(navigator.userAgent);
      },
      BlackBerry: function () {
        return /BlackBerry/i.test(navigator.userAgent);
      },
      iOS: function () {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
      },
    },
  });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <React.Fragment>
      <section id="home">
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source src="video/fondo-contacto.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div
                className="wrapper-title-home"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                <div className="wrapper-titles-home">
                  <h2 
                   className={
                    status.isMobile.Android() === true
                      ? "title-home-wine-friends didot"
                      : "title-home-wine-friends"
                  }
                  // className="title-home-wine-friends"
                  >
                  {t('v-contacto.textimg-1')}
                    <span className="title-home-wine-friends-dos">{t('v-contacto.textimg-2')}</span>
                  </h2>
                </div>
                <div className="wrapper-titles-home">
                  <h2 
                   className={
                    status.isMobile.Android() === true
                      ? "title-home-wine-friends didot"
                      : "title-home-wine-friends"
                  }
                  // className="title-home-wine-friends"
                  >
                  {t('v-contacto.textimg-3')}
                    <span className="title-home-wine-friends-dos">{t('v-contacto.textimg-4')}</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="formulario">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8">
            <p className="txt-form only-desktop">
            {t('v-contacto.m-parrafo-1')} <br/>
            {t('v-contacto.m-parrafo-2')}  <br/>
            {t('v-contacto.m-parrafo-3')} 
            </p>
            <p 
             className={
              status.isMobile.Android() === true
                ? "txt-form only-mobile didot"
                : "txt-form only-mobile"
            }
            // className="txt-form only-mobile"
            >
            {t('v-contacto.parrafo')} 
            </p>
          </div>
        </div>
      <Formulario />
      </div>
    </section>
    </React.Fragment>
  );
};
